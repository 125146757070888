<script setup lang="ts">

import { Link } from '@inertiajs/vue3'
import TheIcon from '@/Components/TheIcon.vue'
import { ref, onMounted, watch } from 'vue'
import { 
    comparisonIds,
    comparisonCount,
    addComparison,
    removeComparison
} from '@/Composables/use-comparison'

type Props = {
    listingId: number,
    showComparisonTooltip?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showComparisonTooltip: true
})

const MAX_ITEMS = 3
const active = ref(false)
const activeMessage = ref(false)

onMounted(() => {
    if (comparisonIds.value && comparisonIds.value.includes(props.listingId)) {
        active.value = true
    }
})

watch(() => comparisonIds.value, () => {
    if(comparisonIds.value.includes(props.listingId)) {
        active.value = true
    } else {
        active.value = false
    }
})

const handleClick = (): void => {
    if (active.value && !activeMessage.value) {
        removeListingFromCompare(props.listingId)
    } else {
        addListingToCompare(props.listingId)
    }
}

const addListingToCompare = async (listingId: number): Promise<void> => {
    if (comparisonCount.value >= MAX_ITEMS) {
        activeMessage.value = true
        setTimeout(() => activeMessage.value = false, 3000)

        return
    }

    addComparison(listingId)

    activeMessage.value = true

    setTimeout(() => activeMessage.value = false, 3000)

    active.value = true
}

const removeListingFromCompare = async (listingId: number): Promise<void> => {
    removeComparison(listingId)

    active.value = false
}

</script>

<template>
    <div
        v-if="active"
        class="position-relative comparison comparison--active"
    >
        <div
            class="card-fav-icon ml-1 position-relative z-index-2 cursor-pointer comparison-icon bg-dark"
            @click="handleClick"
        >
            <TheIcon
                width="20"
                class="d-flex"
                height="20"
                icon-id="comparison-tool-white"
            />
        </div>
        <div
            v-if="activeMessage"
            class="alert alert-warning comparison-alert text-left"
        >
            <div v-if="comparisonCount === 1">
                Add more hostels to compare!
            </div>

            <div v-if="comparisonCount === 2">
                <div class="d-inline">
                    Compare up to {{ MAX_ITEMS }} hostels.
                </div>
                <Link :href="route('comparison')" class="cl-link">
                    Compare now!
                </Link>
            </div>

            <div v-if="comparisonCount === 3">
                <Link :href="route('comparison')" class="cl-link">
                    Compare
                </Link>
                <div> all {{ MAX_ITEMS }} hostels now!</div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="position-relative comparison comparison--not-active"
    >
        <div
            class="card-fav-icon ml-1 position-relative z-index-2 cursor-pointer bg-light comparison-icon"
            @click="handleClick"
        >
            <TheIcon
                width="20"
                height="20"
                class="d-flex"
                icon-id="comparison-tool-2"
            />
        </div>
        <div v-if="showComparisonTooltip" class="comparison-tooltip">
            Add to Hostel Comparizon tool
        </div>
        <div
            v-if="activeMessage"
            class="alert alert-warning comparison-alert text-left"
        >
            <div>
                <div class="d-inline">
                    You can compare {{ MAX_ITEMS }} hostels at once!
                </div>

                <Link :href="route('comparison')" class="cl-link">
                    Compare now!
                </Link>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.comparison {
    opacity: 1;

    &--not-active {
        &:hover {
            .comparison-tooltip {
                opacity: 1;  
            }
        }
    }

    &-icon {
        opacity: 1;
        transition: opacity 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.07);

        &:hover {
            opacity: 0.7;
        }
    }

    &-alert {
        min-width: 200px;
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 18px;
        z-index: 100;
    }

    &-tooltip {
        position: absolute;
        top: 45px;
        right: 50%;
        opacity: 0;
        transition: opacity .3s;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 14px;
        min-width: max-content;
        transform: translateX(50%);
        background-color: #000;
        z-index: 2;
        pointer-events: none;

        &::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid black;
        }
    }
}

.comparison-alert > div {
    line-height: 1.5;
}

</style>