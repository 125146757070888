<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons'
import { computed, onMounted, ref } from 'vue'
import { 
    debouncedWishlistsIsActiveEndpoint,
    deleteUserWishlistEndpoint,
    selectedLocationID,
    setSelectedLocationID,
    setUserWishListLocationsIDs,
    setUserWishlists,
    userWishlistsEndpoint
} from '@/Composables/wishlistLogic'
import { setToastData, setToastShow, setToastShowAtAdding } from '@/Composables/toastLogic'
import { isUserLogged } from '@/Composables/authorization'
import { WishlistModalData, WishlistDeleteOrCreate } from '@/Interfaces/wishlist/wishlist'
import { usePage } from '@inertiajs/vue3'

type Props = {
    hostelId: number,
    userWishListLocationsIDs: number[],
    showWishlistTooltip?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    showWishlistTooltip: true
})

const emit = defineEmits([
    'openWishlistModal'
])

const page = usePage()

onMounted(() => {
    if (page.props.user && page.props.user?.wishlist.length) {
        const request = {
            url: '/wishlists/isActive'
        }

        debouncedWishlistsIsActiveEndpoint(request, (data) => {

            if (data.listings) {
                setUserWishListLocationsIDs(data.listings)
            }

        })
    }
})

const locationIsSelected = ref(false)

const setLocationIsSelected = (isSelected: boolean): void => {
    locationIsSelected.value = isSelected
}

const locationsInTheUsersWishlists = computed(() => {
    
    if(props.userWishListLocationsIDs && props.userWishListLocationsIDs.includes(props.hostelId)){
        setLocationIsSelected(true)

        return faSolidHeart
    } else {
        setLocationIsSelected(false)

        return faHeart
    } 
})

const openWishlistModal = (data: WishlistModalData): void => {

    setSelectedLocationID(data.hostelID)

    if (data.isSelectedLocation && isUserLogged.value && selectedLocationID.value) {
        const removeLocationFromList = props.userWishListLocationsIDs.filter(locationId => locationId !== selectedLocationID.value)

        setUserWishListLocationsIDs(removeLocationFromList)

        const request = {
            url: '/wishlists/listing',
            listingID: selectedLocationID.value
        }

        deleteUserWishlistEndpoint(request, (data) => {

            if (data.wishlist && 'path' in data.wishlist) {
                setToastData(data.wishlist as WishlistDeleteOrCreate)

                setToastShowAtAdding(false)

                setToastShow(true)
            }

            const request = {
                url: '/wishlists/userLists'
            }

            userWishlistsEndpoint(request, (data) => {

                if (Array.isArray(data.wishlists)) {
                    setUserWishlists(data.wishlists)

                } else {
                    console.error('Unexpected data format after response from userWishlistsEndpoint:', data.wishlists)
                }
            })
        })
    } else {
        emit('openWishlistModal')
    }
}

const putOrRemoveFromWishlist = (hostelID: number, isSelectedLocation: boolean): void => {
    openWishlistModal({ hostelID, isSelectedLocation })
} 

</script>

<template>
    <div
        class="position-relative z-index-2  ml-1 locations-at-user-wishlists"
        @click="putOrRemoveFromWishlist(hostelId, locationIsSelected)"
    >
        <div v-if="showWishlistTooltip" class="locations-at-user-wishlists-tooltip">
            Add to Hostel wishlist
        </div>

        <div class="locations-at-user-wishlists-icon">
            <FontAwesomeIcon :icon="locationsInTheUsersWishlists" class="cl-primary" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.locations-at-user-wishlists {

    &:hover {
        .locations-at-user-wishlists-tooltip {
            opacity: 1;
        }
    }

    &-icon {
        cursor: pointer;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        background: #343a40;
        line-height: 2.5rem;
        
        position: relative;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0px 6px 2px -4px #0E0E2C14;
        transition: opacity .3s;


        &:hover {
            opacity: 0.7;
            .locations-at-user-wishlists-tooltip {
                opacity: 1;
            }
        }

        svg {
            font-size: 24px;
        }
    }

    &-tooltip {
        position: absolute;
        top: 45px;
        right: 50%;
        opacity: 0;
        transition: opacity .3s;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 14px;
        min-width: max-content;
        transform: translateX(50%);
        background-color: #000;
        z-index: 2;
        pointer-events: none;

        &::before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid black;
        }
    }
}
</style>