import { ToastData } from '@/Interfaces/toast/toast'
import { ref } from 'vue'

export const toastShow = ref(false)

export const setToastShow = (show: boolean): void => {
    toastShow.value = show
}

export const toastData = ref<ToastData | null>(null)

export const setToastData = (data: ToastData): void => {
    toastData.value = data
}

export const toastShowAtAdding = ref(false)

export const setToastShowAtAdding = (data: boolean): void => {
    toastShowAtAdding.value = data
}
