import { ref } from 'vue'
import axios from 'axios'
import { 
    AddItemToWishlistEndpoint,
    DeleteUserWishlistEndpoint,
    UserWishlistsEndpoint,
    Wishlist,
    WishlistsCreateEndpoint,
    WishlistServerResponse,
    WishlistsIsActiveEndpoint
} from '@/Interfaces/wishlist/wishlist'
import { useDebounceFn } from '@vueuse/core'

export const isLoadingRequestWishlist = ref(false)

export const userWishlists = ref<Wishlist[]>([])

export const setUserWishlists = (wishlists: Wishlist[]):void => {
    userWishlists.value = wishlists
}

export const userWishListLocationsIDs = ref<number[]>([])

export const setUserWishListLocationsIDs = (idsArr: number[]):void => {
    userWishListLocationsIDs.value = idsArr
}

export const statusFromServer = ref('')

export const setStatusFromServer = (value: string): void => {
    statusFromServer.value = value
} 

export const selectedLocationID = ref<number | null>(null)

export const setSelectedLocationID = (locationID: number): void => {
    selectedLocationID.value = locationID
} 


export const clearWishlistData = (): void => {
    setStatusFromServer('')
}

//for slp
export const showWishlistModal = ref(false)

export const closeWishlistModal = (): void => {
    showWishlistModal.value = false
}

export const openWishlistModal = (): void => {
    showWishlistModal.value = true
}

export const debouncedWishlistsIsActiveEndpoint = useDebounceFn((request: WishlistsIsActiveEndpoint, ...callbacks: Array<(data: WishlistServerResponse) => void>): void => {
    wishlistsIsActiveEndpoint(request, ...callbacks)
}, 300)

// items at all wishlists
export const wishlistsIsActiveEndpoint = (request: WishlistsIsActiveEndpoint, ...callbacks: Array<(data: WishlistServerResponse) => void>): void => {
    isLoadingRequestWishlist.value = true
    
    axios
        .get( request.url  )
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))

            isLoadingRequestWishlist.value = false 
        })
        .catch((error) => {
            console.error('Error at wishlists is active endpoint:', error)

        })
}

// wishlists create
export const wishlistsCreateEndpoint = (request: WishlistsCreateEndpoint, ...callbacks: Array<(data: WishlistServerResponse) => void>): void => {
    isLoadingRequestWishlist.value = true

    const postData = {
        name: request.name
    }

    axios
        .post( request.url, postData )
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))

            isLoadingRequestWishlist.value = false
            
        })
        .catch((error) => {
            console.error('Error at wishlists create endpoint:', error)

        })
}


//get user wishlists
export const userWishlistsEndpoint = (request: UserWishlistsEndpoint, ...callbacks: Array<(data: WishlistServerResponse) => void>): void => {
    isLoadingRequestWishlist.value = true

    axios
        .get( request.url)
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))

            isLoadingRequestWishlist.value = false
     
        })
        .catch((error) => {
            console.error('Error at user wishlists endpoint:', error)

        })
}

// add item to wishlists 
export const addItemToWishlistEndpoint  = (request: AddItemToWishlistEndpoint, ...callbacks: Array<(data: WishlistServerResponse) => void>): void => {
    isLoadingRequestWishlist.value = true

    axios
        .post( `/wishlists/${ request.wishlistID }/listing/${ request.listingID  }` )
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))

            isLoadingRequestWishlist.value = false
            
        })
        .catch((error) => {
            console.error('Error at wishlists create endpoint:', error)

        })
}


//delete user wishlist
export const deleteUserWishlistEndpoint  = (request: DeleteUserWishlistEndpoint, ...callbacks: Array<(data: WishlistServerResponse) => void>): void => {
    isLoadingRequestWishlist.value = true

    axios
        .delete( `${ request.url }/${ request.listingID } `)
        .then((response) => {
            callbacks.forEach(callback => callback(response.data))

            isLoadingRequestWishlist.value = false
            
        })
        .catch((error) => {
            console.error('Error at wishlists create endpoint:', error)

        })
}
